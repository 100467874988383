import React from 'react';
import { Switch, Redirect } from 'react-router-dom';

import { RouteWithLayout } from './components';
import { Main as MainLayout, Minimal as MinimalLayout } from './layouts';

import {
  Dashboard as DashboardView,
  ProductList as ProductListView,
  UserList as UserListView,
  Typography as TypographyView,
  Icons as IconsView,
  Account as AccountView,
  Settings as SettingsView,
  SignUp as SignUpView,
  SignIn as SignInView,
  Registro as RegistroView,
  Salida as SalidaView,
  RealTimeDashboard as RealTimeDashboardView,
  EasyIn as EasyInView,
  NotFound as NotFoundView,
  EntradaQR as EntradarQR,
  SalidaQR as SalidaQR,
  AttendeesList as AttendeesList,
  EventosAdmin as EventosAdmin,
  Confirmation as Confirmation,
  InvitadoAdmin as InvitadoAdmin,
  SelfRegistration as SelfRegistration,
  SelfRegistrationKeyboard as SelfRegistrationKeyboard,
  EntradaQRExpositores as EntradaQRExpositores
} from './views';

const Routes = () => {
  return (
    <Switch>
      <Redirect
        exact
        from="/"
        to="/eventos"
      />
      <RouteWithLayout
        component={DashboardView}
        exact
        layout={MainLayout}
        path="/dashboard"
      />
      <RouteWithLayout
        component={RealTimeDashboardView}
        exact
        layout={MainLayout}
        path="/eventos/realtimedashboard/:room"
      />
      <RouteWithLayout
        component={UserListView}
        exact
        layout={MainLayout}
        path="/eventos"
      />
      <RouteWithLayout
        component={EasyInView}
        exact
        layout={MainLayout}
        path="/eventos/inout/:eventId"
      />
      <RouteWithLayout
        component={EntradarQR}
        exact
        layout={MainLayout}
        path="/eventos/inqr/:eventId"
      />
      <RouteWithLayout
        component={EntradaQRExpositores}
        exact
        layout={MinimalLayout}
        path="/eventos/qr/:expositorId"
      />      
      <RouteWithLayout
        component={SalidaQR}
        exact
        layout={MainLayout}
        path="/eventos/outqr/:eventId"
      />
      <RouteWithLayout
        component={RegistroView}
        exact
        layout={MainLayout}
        path="/eventos/in/:eventId"
      />
      <RouteWithLayout
        component={SalidaView}
        exact
        layout={MainLayout}
        path="/eventos/out/:eventId"
      />
      <RouteWithLayout
        component={AttendeesList}
        exact
        layout={MainLayout}
        path="/eventos/attendance/:eventId"
      />
      <RouteWithLayout
        component={EventosAdmin}
        exact
        layout={MainLayout}
        path="/eventos/admin"
      />
      <RouteWithLayout
        component={EventosAdmin}
        exact
        layout={MainLayout}
        path="/eventos/admin/:eventId"
      />
      <RouteWithLayout
        component={ProductListView}
        exact
        layout={MainLayout}
        path="/products"
      />
      <RouteWithLayout
        component={Confirmation}
        exact
        layout={MinimalLayout}
        path="/eventos/confirmacion/:eventId/:invitadoId"
      />
      <RouteWithLayout
        component={InvitadoAdmin}
        exact
        layout={MinimalLayout}
        path="/eventos/registro/:eventId"
      />
      <RouteWithLayout
        component={SelfRegistration}
        exact
        layout={MinimalLayout}
        path="/eventos/selfregistration"
      />
      <RouteWithLayout
        component={SelfRegistrationKeyboard}
        exact
        layout={MinimalLayout}
        path="/eventos/selfregistrationkeyboard"
      />
      <RouteWithLayout
        component={TypographyView}
        exact
        layout={MainLayout}
        path="/typography"
      />
      <RouteWithLayout
        component={IconsView}
        exact
        layout={MainLayout}
        path="/icons"
      />
      <RouteWithLayout
        component={AccountView}
        exact
        layout={MainLayout}
        path="/account"
      />
      <RouteWithLayout
        component={SettingsView}
        exact
        layout={MainLayout}
        path="/settings"
      />
      <RouteWithLayout
        component={SignUpView}
        exact
        layout={MinimalLayout}
        path="/sign-up"
      />
      <RouteWithLayout
        component={SignInView}
        exact
        layout={MinimalLayout}
        path="/sign-in"
      />
      <RouteWithLayout
        component={NotFoundView}
        exact
        layout={MinimalLayout}
        path="/not-found"
      />
      <Redirect to="/not-found" />
    </Switch>
  );
};

export default Routes;
